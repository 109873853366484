import qs from 'qs';
import { request } from '@utils';
import { getCompanyId, getStaffId } from '@globalUtils';

export const getCompanyInfo = async () => request(`/companies/${getCompanyId()}`, {
  method: 'GET',
});

export const getStaffInfo = async () => request(`/accounts/${getStaffId()}`, {
  method: 'GET',
});
