import qs from 'qs';
import PropTypes from 'prop-types';
import { getMcKey } from './authority';
import { envConf, appConf } from '../config';
import { select } from './store';
import { callNativeLocalSetting, getNativeInfo } from './callNative';

// /**
//  * Requests a URL, returning a promise.
//  *
//  * @param  {string} url       The URL we want to request
//  * @param  {object} [options] The options we want to pass to "fetch"
//  * @return {object}           An object containing either "data" or "err"
//  */
//
// return {
//   success: boolean,
//   data: core data json,
//   status: number,
//   errorMessage: string,
//   // more...
//   errorType: enums,
//   errorItems: string,
//   errorCode: number,
//   errorDebugInfo: number,
// }

const optionType = {
  method: PropTypes.oneOf(['GET', 'POST', 'PATCH', 'PUT', 'DELETE']),
  debug: PropTypes.bool,
  timeout: PropTypes.number,
};

const asyncResultType = {
  success: PropTypes.bool,
  data: PropTypes.object,
  status: PropTypes.number,
  errorMessage: PropTypes.string,
  errorType: PropTypes.oneOf(['biz_error', 'param_error', 'no_content', 'json_parse_error', 'network_error', 'timeout_error']),
  errorItems: PropTypes.any,
  errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorDebugInfo: PropTypes.any,
};

async function request(url, options = {}) {
  let {
    method = 'GET',
    timeout = 15000,
  } = options;
  method = method.toUpperCase();

  PropTypes.checkPropTypes(optionType, options, 'options', 'request(url, options)');

  // 自动追加API域名前缀
  url = url.indexOf('http') === 0 ? url : `${appConf.mcApiBase}${url}`;
  // 统一追加 mcKey
  if ( !(/[?&]key=/).test(url) ) {
    const nativeInfo = await getNativeInfo();
    const mcKey = (
      nativeInfo?.name === "MS"
        ? await callNativeLocalSetting("mcKey")
        : await getMcKey()
    );
    if ( mcKey ) {url += `${url.indexOf('?') === -1 ? '?' : '&'}key=${mcKey}`;}
  }
  // 统一追加 lang
  if ( !(/[?&]lang=/).test(url) ) {
    const currentLang = (await select(({i18n})=>i18n)).currentLang || '';
    if ( currentLang ) {url += `${url.indexOf('?') === -1 ? '?' : '&'}lang=${currentLang}&defaultLang=en`;}
  }

  // body 别名 data
  if (options.data) {
    options.body = options.data;
    delete options.data;
  }

  // 剔除 undefined 的参数
  if (options.body) {
    Object.keys(options.body).forEach((key)=>{
      if (options.body[key] === undefined) {
        delete options.body[key];
      }
    });
  }

  // POST like param passing
  if (['POST', 'PATCH', 'PUT'].includes(options.method)) {
    options.headers = Object.assign({
      // 'Accept': 'application/json',
      // 'Content-Type': 'application/json; charset=utf-8',
    }, options.headers);
    const finalContentType = (options.headers['Content-Type'] || '').toLowerCase();
    // if (options.body && finalContentType.indexOf('application/json') === 0 ) {
    //   options.body = JSON.stringify(options.body);
    // }
    // else if (options.body && (
    //   finalContentType.indexOf('application/x-www-form-urlencoded') === 0
    //   || finalContentType.indexOf('multipart/form-data') === 0
    // )) {
    const formData = new FormData();
    for (const key in options.body) {
      if (Object.hasOwnProperty.call(options.body, key)) {
        let value = options.body[key];
        if (typeof value === 'object') {
          try {
            value = JSON.stringify(value);
          } catch (error) {}
        }
        else if (typeof value === 'undefined') {
          // Skip
          continue;
        }
        formData.append(key, value);
      }
    }
    options.body = formData;
    // }
  }
  // GET like param passing
  else if (options.body) {
    url += (url.indexOf('?') === -1 ? '?' : '&') + qs.stringify(options.body);
    delete options.body;
  }

  // For some env which cannot print info in console
  if (options.debug) {
    console.log(`[Request] ${  options.method  }: ${  url}`);
    if (options.body) {
      console.log(`[ - Data] ${  options.body}`);
    }
  }

  const requestObject = {
    ...options,
    url,
  };

  const resultObj = {
    success: true,
    data: null,
    request: requestObject,
    // Response info
    response: null,
    status: null,
    // Failed info
    errorType: null,
    errorMessage: null,
    errorItems: null,
    errorCode: null,
    errorDebugInfo: null,
  };

  const requestPromise = new Promise(((resolve) => {
    const responseObj = {
      status: undefined,
      rawResponse: undefined,
    };
    fetch(url, options).then((response) => {
      responseObj.status = response.status;
      responseObj.rawResponse = response;
      return response.text();
    }, (e) => {
      // Failed info
      resultObj.success = false;
      resultObj.errorType = 'network_error';
      resultObj.errorDebugInfo = e;
      resolve(resultObj);
    }).then((responseText) => {
      // Response info
      resultObj.status = responseObj.status;
      resultObj.response = responseObj.rawResponse;

      const isNoContentSuccess = responseObj.status === 204 && responseText === '';

      // Parse JSON, fill in 'data'
      if (isNoContentSuccess) {
        resultObj.data = null;
      }
      else {
        if ( options.json === false ) {
          resultObj.data = responseText;
        }
        else {
          try {
            resultObj.data = JSON.parse(responseText);
          } catch (e) {
            // Failed info
            resultObj.success = false;
            resultObj.errorType = 'json_parse_error';
            resultObj.errorDebugInfo = e;
          }
        }
      }

      // Return result
      // 1XX, 2XX success
      if (responseObj.status < 300) {
        // Handled Biz error, 500, 404, 401, 403...
        if (Number(resultObj.data?.err)) {
          // Failed info
          resultObj.success = false;
          resultObj.errorType = 'biz_error';
          resultObj.errorCode = Number(resultObj.data.err);
          resultObj.errorMessage = resultObj.data.msg;
          resultObj.errorDebugInfo = resultObj.data.debug_msg;
          // Clear data
          resultObj.data = null;
          return resolve(resultObj);
        }
        else {
          // Return
          return resolve(resultObj);
        }
      }
      // 400 Bad Request
      else if (responseObj.status === 400) {
        // Failed info
        resultObj.success = false;
        resultObj.errorType = 'param_error';
        return resolve(resultObj);
      }
      // Handled Biz error, 500, 404, 401, 403...
      else if (responseObj.status <= 500) {
        // Failed info
        resultObj.success = false;
        resultObj.errorType = resultObj.data ? 'biz_error' : 'no_content';
        resultObj.errorCode = resultObj.data.errorCode;
        resultObj.errorMessage = resultObj.data.errorMessage;
        resultObj.errorItems = resultObj.data.errorItems;
        resultObj.errorDebugInfo = resultObj.data.errorDebugInfo;
        // Clear data
        resultObj.data = null;
        return resolve(resultObj);
      }
      else {
        // Failed info
        resultObj.success = false;
        return resolve(resultObj);
      }
    });
  }));

  let timeoutTmo;
  const timeoutPromise = new Promise(resolve => {
    timeoutTmo = setTimeout(()=>{
      // Failed info
      resultObj.success = false;
      resultObj.errorType = 'timeout_error';
      resolve(resultObj);
    }, timeout);
  });

  return Promise.race([requestPromise, timeoutPromise]).then((res)=>{
    clearTimeout(timeoutTmo);
    return res;
  });
}

module.exports = request;
