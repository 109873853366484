import React from 'react';
import { StyleSheet, View, Text, Image, PixelRatio, Dimensions, KeyboardAvoidingView, Platform, Linking } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Touchable from './Touchable';
import TextInput from './TextInput';
import resources from '../config/resources';
import { inputValueLimit } from '../utils/inputValueLimit';

class DialogContent extends React.PureComponent {

  static propTypes = {
    type: PropTypes.oneOf(['alert', 'confirm', 'prompt', 'reload', 'needReturn']),
    title: PropTypes.string,
    content: PropTypes.node,
    icon: PropTypes.node,
    placeholder: PropTypes.string,
    inputValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    keyboardType: PropTypes.string,
    leftBtnText: PropTypes.node,
    onLeftClick: PropTypes.func,
    rightBtnText: PropTypes.node,
    onRightClick: PropTypes.func,
    disabledRightBtnOnPromptEmpty: PropTypes.bool,
    disabledRightBtnOnPromptZero: PropTypes.bool,
    disabledRightBtnOnPromptPoint: PropTypes.bool,
    showDelete: PropTypes.bool,
  }

  static defaultProps = {
    type: 'alert', //
    title: '',
    content: '',
    icon: null,
    placeholder: '',
    inputValue: '',
    keyboardType: 'default',
    leftBtnText: '',
    onLeftClick: null,
    rightBtnText: '',
    onRightClick: null,
    disabledRightBtnOnPromptEmpty: true,
    disabledRightBtnOnPromptZero: false,
    disabledRightBtnOnPromptPoint: false,
    showDelete: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      inputValueText: props.inputValue,
      check: false,
    };
  }

  render() {
    let {
      i18n,
      type, boxStyle,
      title, titleStyle,
      content, contentStyle,
      icon, inputLimitType,
      placeholder, keyboardType, inputValue, inputMaxValue, inputStyle, hideInputBottomBorder, beforeInputText, afterInputText, underInput, underInputWithCheck, underInputWithCheckText,
      leftBtnText, onLeftClick, leftBtnStyle,
      rightBtnText, onRightClick, rightBtnStyle, disabledRightBtnOnPromptEmpty, disabledRightBtnOnPromptZero, disabledRightBtnOnPromptPoint, showDelete,
      hide,
      rest,
    } = this.props;

    const { inputValueText, check } = this.state;

    if (type === 'reload') {
      title = i18n.map.app_network_error_tip;
      leftBtnText = Platform.OS === 'ios' ? i18n.map.go_set : null;
      rightBtnText = i18n.map.retry;
      leftBtnStyle = { color: '#0091FF' };
      let onLeftClickOrigan = onLeftClick;
      onLeftClick = () => {
        Linking.openURL('app-settings://notification/myapp');
        onLeftClickOrigan();
      };
    }

    const rightBtnDisable = (inputMaxValue && (Number(inputValueText) > Number(inputMaxValue))) || (disabledRightBtnOnPromptEmpty && type === 'prompt' && !inputValueText) || (disabledRightBtnOnPromptZero && type === 'prompt' && Number(inputValueText) === 0 && inputValueText !== "") || (disabledRightBtnOnPromptPoint && type === 'prompt' && inputValueText.includes('.'));

    const { width: windowWidth, height: windowHeight } = Dimensions.get('window');

    const dialogWidth = Math.min((270 / 375 * windowWidth), 400);
    const containerMarginHorizontal = (windowWidth - dialogWidth) / 2;
    let styles_container = [styles.container, { width: windowWidth, height: windowHeight }];
    let styles_dialogContainer = [styles.dialogContainer, { marginHorizontal: containerMarginHorizontal }];
    let styles_inputBlock = [styles.inputBlock, { width: dialogWidth - 56 }, hideInputBottomBorder && { borderBottomWidth: 0 }];
    let styles_onlyInput = [styles.onlyInput, { width: dialogWidth - 56 }];
    let styles_checkBlock = [styles.checkBlock, { width: dialogWidth - 56 }];

    return (
      <View style={styles_container}>
        <KeyboardAvoidingView behavior="padding" enabled>
          <View style={styles_dialogContainer}>
            <View style={[styles.box, boxStyle]}>
              {icon}
              {title ? <Text style={[styles.dialogTitle, content ? '' : styles.dialogOnlyTitle, titleStyle]}>{title}</Text> : null}
              {content && typeof content === 'string' ? <View style={[styles.dialogContent]}><Text style={[styles.contentStyle, contentStyle]}>{content}</Text></View> : null}
              {content && typeof content !== 'string' ? <View style={[styles.dialogContent]}>{content}</View> : null}
              {type === 'prompt' ?
                <View>
                  <View style={styles_inputBlock}>
                    {beforeInputText ? <View style={styles.beforeInputBlock}><Text style={styles.beforeInputText}>{beforeInputText}</Text></View> : null}
                    <TextInput
                      style={[styles.inputStyle, (beforeInputText || afterInputText) ? '' : styles_onlyInput, inputStyle]}
                      placeholder={placeholder}
                      placeholderTextColor="#BEBEBE"
                      value={inputValueText}
                      onChangeText={text => {
                        let value = text;
                        if (inputLimitType) {
                          value = inputValueLimit(value, inputLimitType);
                        }
                        this.setState({ inputValueText: value });
                        this.props.handelInput(value);
                      }}
                      autoFocus
                      autoCapitalize="none"
                      keyboardType={keyboardType}
                    />
                    {afterInputText ? <View style={styles.beforeInputBlock}><Text style={styles.afterInputText}>{afterInputText}</Text></View> : null}
                    {
                      showDelete && inputValueText ?
                        <Touchable style={styles.clearBtn} onPress={() => {
                          this.setState({ inputValueText: '' });
                          this.props.handelInput('');
                        }}>
                          <Image style={styles.clearImage} source={resources.iconDelete} />
                        </Touchable> : null
                    }
                  </View>
                  {underInputWithCheck ?
                    <Touchable style={styles.checkBtn} onPress={() => this.setState({ check: !check })}>
                      <View style={styles_checkBlock} >
                        <Image source={check ? resources.multipleSelected : resources.multipleUnselected} style={styles.checkIcon} />
                        <Text style={styles.checkText} >{underInputWithCheckText}</Text>
                      </View>
                    </Touchable> : null
                  }
                  {underInput ? underInput() : null}
                </View>
                : null}
            </View>

            {/* Buttons */}
            <View style={styles.buttonContainer}>
              {leftBtnText ? (
                <Touchable
                  onPress={() => {
                    if (hide && type !== 'reload') { hide(); }
                    setTimeout(() => { if (onLeftClick) { onLeftClick(); } });
                  }}
                  style={[styles.dialogButton, styles.leftButton]}>
                  <Text style={[styles.buttonText, styles.leftBtnText, leftBtnStyle]}>{leftBtnText}</Text>
                </Touchable>) : null
              }
              <Touchable
                onPress={() => {
                  if (hide) { hide(); }
                  setTimeout(() => { if (onRightClick) { underInputWithCheck ? onRightClick(check) : onRightClick(); } });
                }}
                disabled={rightBtnDisable}
                style={[styles.dialogButton, styles.rightButton, leftBtnText ? {} : styles.leftButton]}
              >
                <Text style={[styles.buttonText, styles.rightBtnText, rightBtnStyle, rightBtnDisable ? styles.leftBtnText : '']}>{rightBtnText}</Text>
              </Touchable>
            </View>

          </View>
        </KeyboardAvoidingView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
  },
  dialogContainer: {
    borderRadius: 12,
    backgroundColor: 'rgb(255,255,255)',
  },
  box: {
    alignItems: 'center',
    alignSelf: 'center',
    paddingVertical: 15,
  },
  dialogTitle: {
    textAlign: 'center',
    fontSize: 17,
    lineHeight: 21,
    paddingHorizontal: 15,
    color: '#030303',
  },
  dialogOnlyTitle: {
    paddingVertical: 12,
    paddingHorizontal: 15,
  },
  dialogContent: {
    marginTop: 14,
    paddingHorizontal: 20,
    alignSelf: 'center',
  },
  contentStyle: {
    fontSize: 14,
    lineHeight: 18,
    color: '#030303',
    textAlign: 'center',
  },
  beforeInputBlock: {
    paddingBottom: 9,
  },
  inputBlock: {
    minHeight: 30,
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderStyle: 'solid',
    borderBottomColor: '#A0A0A0',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: 15,
  },
  beforeInputText: {
    fontSize: 16,
    color: '#000',
    fontWeight: '600',
    paddingRight: 10,
  },
  afterInputText: {
    fontSize: 16,
    color: '#000',
    fontWeight: '600',
    paddingLeft: 10,
  },
  inputStyle: {
    textAlign: 'center',
    fontSize: 16,
    color: '#221503',
    paddingBottom: Platform.OS === 'ios' ? 9 : 6,
    flexDirection: 'row',
  },
  onlyInput: {
  },
  buttonContainer: {
    flexDirection: 'row',
  },
  dialogButton: {
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: 'rgba(132,132,132,0.5)',
  },
  leftButton: {
    borderBottomLeftRadius: 12,
    marginRight: -(StyleSheet.hairlineWidth),
    borderRightWidth: StyleSheet.hairlineWidth,
    borderRightColor: 'rgba(132,132,132,0.5)',
  },
  rightButton: {
    borderBottomRightRadius: 12,
  },
  buttonText: {
    fontSize: 16,
  },
  leftBtnText: {
    color: '#787878',
  },
  rightBtnText: {
    color: '#0091FF',
  },
  clearBtn: {
    height: 30,
    justifyContent: 'center',
  },
  clearImage: {
    height: 18,
    width: 18,
  },

  checkBlock: {
    minHeight: 18,
    flexDirection: 'row',
    marginTop: 12,
  },
  checkIcon: {
    height: 22,
    width: 22,
    marginRight: 5,
  },
  checkText: {
    fontSize: 13,
    color: '#787878',
    lineHeight: 18,
    flex: 1,
  },
});

export default connect(({ i18n, app }) => ({ i18n, app }))(DialogContent);
