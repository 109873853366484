import qs from 'qs';
import { createAction, delay, handleRequestErrorInModel, authority, callNativeLog, ensureValidObject } from '@utils';
import * as mcAccountService from '../../services/mcAccount';
import * as msAccountService from "../../services/msAccount";
import { Theme } from 'ui-m/rn';

export default {
  namespace: 'auth',
  state: {
    mcKey: null,
    loginToken: null,
    // 登录账号信息
    isValid: false,
    companyInfo: {},
    staffInfo: {},
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    setLoginInfo(state, { payload }) {
      const mcKey = payload.mcKey || state.mcKey;
      const loginToken = payload.loginToken || state.loginToken;
      const isValid = !!mcKey || !!loginToken;
      return {
        ...state,
        mcKey,
        loginToken,
        isValid,
      };
    },
    setLoginInvalid(state, { payload }) {
      return {
        ...state,
        mcKey: null,
        loginToken: null,
        isValid: false,
      };
    },
    clearLoginInfo(state, { payload }) {
      return {
        ...state,
        mcKey: null,
        loginToken: null,
        isValid: false,
        companyInfo: {},
        staffInfo: {},
      };
    },
    setCompanyInfo(state, { payload }) {
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          ...payload,
        },
      };
    },
    setStaffInfo(state, { payload }) {
      return {
        ...state,
        staffInfo: {
          ...state.staffInfo,
          ...payload,
        },
      };
    },
  },
  effects: {
    // 在 models/app.js 中被调度
    *init({ payload = {} }, { call, put, select, take, fork }) {
      const {
        companyId,
        staffId,
      } = payload;
      // 传入的ID直接提早写入（只在没有ID时才写入），打点之类的可以直接用上
      const { companyInfo = {}, staffInfo = {} } = yield select(({ auth }) => auth);
      if (companyId && companyId !== companyInfo.companyId) {
        yield put(createAction('setState')({
          companyInfo: {
            id: companyId,
          },
        }));
      }
      if (staffId && staffId !== staffInfo.staffId) {
        yield put(createAction('setState')({
          staffInfo: {
            id: staffId,
          },
        }));
      }
      // mcKey 或 loginToken 从 url 获取
      if (Theme.isWebInAPP) {
        // ?mcKey= 或者 ?key= 或者 ?loginToken=
        const query = qs.parse(window.location.search.slice(1));
        const mcKey = query.mcKey || query.key;
        const loginToken = query.loginToken;
        if (mcKey || loginToken) {
          yield put(createAction('setLoginInfo')({ mcKey, loginToken }));
        }
      }
    },
    // 在 models/app.js 中被调度
    *checkActive({ payload = {} }, { call, put, select, fork }) {
      const { mcKey, loginToken } = yield select(({ auth }) => auth);
      const { sync = false } = payload;
      let res;
      // 没有 mcKey 时直接打开
      if (!mcKey && !loginToken) {
        res = {
          success: false,
          status: 401,
        };
        yield handleRequestErrorInModel(res, null, { mute: true });
        return res;
      }
      // 有登录记录时
      else {
        // 检查登录态是否有效，并更新用户信息
        function* updateAccountInfo() {
          res = yield yield put(createAction('updateAccountInfo')());
          if (res.success) {
            // 确认已有效登录，抛出生命周期事件供需要的地方 watch
            // 加 auth 是因为似乎 call 有 bug
            yield put(createAction('auth/lifeCycle_authEnsureValid')());
          }
          else {
            yield handleRequestErrorInModel(res);
          }
        }
        if (sync) {
          yield call(updateAccountInfo);
        }
        else {
          yield fork(updateAccountInfo);
        }
      }
      return res;
    },
    // *ensureValidLoggedIn({payload}, { call, put, select, fork }) {
    // },
    // *login({payload: { _beforeFinishedCallback, ...rest} = {}}, { call, put, select, fork }) {
    //   const { currentShopId, isValid } = yield select(({auth})=>auth);
    //   // 首次登陆时延迟进店
    //   if (!currentShopId || !isValid) {
    //     yield put(createAction('setState')({delayLoginIntoHome: true}));
    //   }
    //   const res = yield call(accountService.login, {
    //     ...rest,
    //     // 用于关联 StrangerId 和账号
    //     additionalProperties: {
    //       strangerId: yield call(authority.getUserGuid),
    //     },
    //   });
    //   if (res.success) {
    //     // 登陆成功打点
    //     yield fork(function*(){
    //       yield call(daService.create, {type: 'loggedIn'});
    //     });
    //     yield put(createAction('setLoginInfo')(res.data));
    //     if (typeof _beforeFinishedCallback === 'function') {
    //       const result = yield yield call(_beforeFinishedCallback);
    //       if (result === false) {
    //         return { success: false };
    //       }
    //     }
    //     // 确认已有效登录
    //     yield yield put(createAction('ensureValidLoggedIn')());
    //   }
    //   yield put(createAction('setState')({delayLoginIntoHome: false}));

    //   return res;
    // },
    // *getLoginInfoFromMS({payload}, { call, put, select, fork }) {
    //   const { mcKey } = payload;
    //   if (!mcKey) {
    //     // 微店铺拒接授权
    //     Toast.fail();
    //     return;
    //   }
    //   ScreenLoading.show();
    //   const res = yield call(accountService.msLogin, {
    //     ...payload,
    //   });
    //   if (res.success) {
    //     // 登陆成功打点
    //     yield fork(function*(){
    //       yield call(daService.create, {type: 'loggedIn'});
    //     });

    //     if (!res.data.extra?.lastShopId) {
    //       ScreenLoading.hide();
    //       // 路由切换成登陆建店状态，避免登陆但无店状态访问别的页面
    //       yield put.resolve(createAction('setState')({delayLoginIntoHome: true}));
    //       yield put.resolve(createAction('setLoginInfo')(res.data));
    //       // 没有店铺需要先创建店铺
    //       const navigation = yield yield call(getNavigation);
    //       navigation.navigate('CreateShop', {
    //         scene: 'loginViaMSWithNoShop',
    //       });
    //     }
    //     else {
    //       // 避免isValid切换后直接进店
    //       yield put(createAction('setState')({delayLoginIntoHome: true}));
    //       yield put.resolve(createAction('setLoginInfo')(res.data));
    //       // 有店直接登陆进店。阻塞式更新店铺信息后才进店
    //       yield put.resolve(createAction('ensureValidLoggedIn')());
    //       yield put(createAction('setState')({delayLoginIntoHome: false}));
    //       ScreenLoading.hide();
    //       yield put(createAction('doAfterLoginSuccess')());
    //       yield put(createAction('intention/setState')({sourceMSVersion: undefined}));
    //     }
    //   }
    //   else {
    //     ScreenLoading.hide();
    //     yield handleRequestErrorInModel(res, null, res.errorCode == '000002' ? {showFailIcon: true} : {});
    //   }
    // },
    // *loginViaMS({payload: { _beforeFinishedCallback, ...rest} = {}}, { call, put, select }) {
    //   // 确认已有效登录
    //   yield put.resolve(createAction('ensureValidLoggedIn')());
    //   yield put.resolve(createAction('setState')({delayLoginIntoHome: false}));

    //   yield put(createAction('doAfterLoginSuccess')());
    //   // 登陆进店成功后去除auth/canPriorityMSLogin
    //   yield put(createAction('intention/setState')({sourceMSVersion: undefined}));
    // },
    *loginViaMcQrCode({ payload: { mcKey, ...rest } = {} }, { call, put, select }) {
      yield put(createAction('setLoginInfo')({ mcKey }));
      return yield yield put(createAction('checkActive')({
        sync: true,
      }));
    },
    // *doAfterLoginSuccess({payload}, { call, put, select, fork }) {
    //   const navigation = yield call(getNavigation);
    //   navigation.dispatch(
    //     CommonActions.reset({
    //       index: 0,
    //       routes: [
    //         {name: 'MainStack', screen: 'MainTab'},
    //       ],
    //     })
    //   );
    // },
    *logout({ payload }, { call, put, select }) {
      yield put(createAction('clearLoginInfo')());
      yield handleRequestErrorInModel({
        success: false,
        status: 401,
      }, null, { mute: true });
    },

    *getCompanyInfo({ payload }, { call, put, select, fork, all }) {
      const nativeInfo = yield select(state => state.app.nativeInfo);

      if (["WEB", "MC", "MCS"].includes(nativeInfo.name)) {
        const res = yield call(mcAccountService.getCompanyInfo);
        if (res.success) {
          yield put(createAction('setCompanyInfo')(ensureValidObject(res.data.info)));
        }
        return res;
      }

      if (nativeInfo.name === "MS") {
        const res = yield call(msAccountService.getCompanyInfo);
        if (res.success) {
          yield put(createAction('setCompanyInfo')(res.data));
        }
        return res;
      }

      return { success: true };
    },

    *getStaffInfo({ payload }, { call, put, select, fork, all }) {
      const nativeInfo = yield select(state => state.app.nativeInfo);

      if (["WEB", "MC", "MCS"].includes(nativeInfo.name)) {
        const res = yield call(mcAccountService.getStaffInfo);
        if (res.success) {
          yield put(createAction('setStaffInfo')(ensureValidObject(res.data.data)));
        }
        return res;
      }

      if (nativeInfo.name === "MS") {
        const res = yield call(msAccountService.getStaffInfo);
        if (res.success) {
          yield put(createAction('setStaffInfo')(res.data));
        }
        return res;
      }

      return { success: true };
    },

    *updateAccountInfo({ payload }, { call, put, select, fork, all }) {
      const [comRes, staffRes] = yield all([
        yield put({ type: "getCompanyInfo" }),
        yield put({ type: "getStaffInfo" }),
      ]);

      return [comRes, staffRes].filter(_ => !_.success)[0] || comRes;
    },
  },
  subscriptions: {
    async setup({ dispatch }) {
    },
  },
};
