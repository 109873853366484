import React from 'react';
import _ from 'lodash';
import { BackHandler } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
// import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Theme } from 'ui-m/rn';
import { NavigationBar, ScreenLoading } from '@components';
import { navigationRef, _setNavigationReady, _setLastRootState, _setLastCurrentRoute } from './utils/navigation';
import { connect } from 'react-redux';
import * as daService from './services/dataAnalyses';
import { testId } from './utils/uitest';
import { lazyWithPreload } from "react-lazy-with-preload";
import SplashScreen from "./containers/SplashScreen";

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const parseScreenProps = function (props = {}) {
  let {
    // 以模态框方式从下往上滑动入场，并且页头区域带缩进阴影
    modalPresentation = false,
    // 是否展示在模态框 Stack 中（不一定以模态框动画入场）
    isInModalStack,
    options = {},
    component: NavigationPage = () => null,
    ...rest
  } = props;
  const name = props.name;
  // 以模态框方式入场的，一定在模态框 Stack 里面
  if (modalPresentation) {
    isInModalStack = true;
  }
  // 追加默认 props
  options = {
    headerShown: false,
    cardOverlayEnabled: modalPresentation,
    // TODO 看看 iOS 有没有问题
    // ...modalPresentation ? TransitionPresets.ModalPresentationIOS : TransitionPresets.SlideFromRightIOS,
    ...options,
  };
  const children = (props) => {
    return (
      <NavigationPage
        {...props}
        {...testId(name)}
        isInModalStack={isInModalStack}
        modalPresentation={modalPresentation}
        isGestureBackEnabled={options?.gestureEnabled}
        pageContainerStyle={isInModalStack ? {
          marginTop: Theme.navBarContentHeight + NavigationBar.MODAL_BAR_TOP_SPACE,
        } : null} />
    );
  };
  return {
    options,
    children,
    ...rest,
  };
};

function _createNavigator(propsOfScreenList = [
  /**
   * {
   *   name: 'Home',
   *   component: HomeScreen,
   *   options: {}
   * }
  */
], options = {}) {
  const {
    type = 'stack', // 'stack', 'modal'
  } = options;
  const finalPropsOfScreenList = _.uniqBy(propsOfScreenList, _ => _.name);
  if (propsOfScreenList.length !== finalPropsOfScreenList.length) {
    console.warn(`router.js: ${type} 中有重复的 Screen name, (${propsOfScreenList.map(_ => _.name).join(', ')})`);
  }
  function StackNavigator(props = {}) {
    return (
      <Stack.Navigator modal={type === 'modal'}>
        {finalPropsOfScreenList.map((screenProps, index) => {
          const modalProps = {};
          if (type === 'stack') {
            // 父导航是模态框式打开，那么这个 Stack 的第一个页面也是（用于左上角后退按钮的动态识别，是叉叉还是后退）
            if (index === 0) {
              modalProps.modalPresentation = props.modalPresentation;
            }
            // 父导航是模态框里的页面，那么这个页面也是（用于页头缩进样式的识别）
            else {
              modalProps.isInModalStack = props.isInModalStack;
            }
          }
          else if (type === 'modal') {
            // 如果是第一个页面，不以模态框形式打开
            if (index === 0) {
              // 如果如果父导航是模态页面，加个间距
              modalProps.isInModalStack = props.isInModalStack;
            }
            // 不是第一个页面，用模态框形式打开
            else {
              modalProps.modalPresentation = true;
            }
          }
          return (
            <Stack.Screen
              key={screenProps.name}
              {...parseScreenProps({
                ...modalProps,
                ...screenProps,
              })}
            />
          );
        })}
      </Stack.Navigator>
    );
  }
  StackNavigator.propsOfScreenList = finalPropsOfScreenList;
  return StackNavigator;
}

function createStack(propsOfScreenList) {
  return _createNavigator(propsOfScreenList, { type: 'stack' });
}

function createModal(propsOfScreenList) {
  return _createNavigator(propsOfScreenList, { type: 'modal' });
}

const screenComponentMap = {
  SplashScreen,
  // 开发测试
  FrameworkStates: lazyWithPreload(() => import("./containers/DevTools/FrameworkStates")),
  DevTools: lazyWithPreload(() => import("./containers/DevTools")),
  IntentionLauncher: lazyWithPreload(() => import("./containers/IntentionLauncher")),
  ComponentPlayground: lazyWithPreload(() => import("./containers/ComponentPlayground")),
  // 上下游
  SupplyChain: lazyWithPreload(() => import("./containers/SupplyChain")),
  DashboardViewer: lazyWithPreload(() => import("./containers/SupplyChain/DashboardViewer")),
  SelectSupplier: lazyWithPreload(() => import("./containers/SupplyChain/SelectSupplier")),
  // 报表
  DashboardLogin: lazyWithPreload(() => import("./containers/DashboardLogin")),
  DashboardReports: lazyWithPreload(() => import("./containers/DashboardReports")),
  // 导入商品
  ImportProductFromMS: lazyWithPreload(() => import("./containers/ImportProductFromMS")),
  ImportProductPreference: lazyWithPreload(() => import("./containers/ImportProductFromMS/Preference")),
  // 销售渠道
  SalesChannelList: lazyWithPreload(() => import("./containers/SalesChannel")),
  SalesChannelEfashion: lazyWithPreload(() => import("./containers/SalesChannel/Efashion")),
  // 配送服务商
  ShippingServiceProviderList: lazyWithPreload(() => import("containers/ShippingServiceProvider")),
  ShippingServiceProviderSelectShippingMethods: lazyWithPreload(() => import("containers/ShippingServiceProvider/SelectShippingMethods")),
  ShippingServiceProviderSetting37Express: lazyWithPreload(() => import("containers/ShippingServiceProvider/Setting37Express")),
  ShippingServiceProviderConnect37Express: lazyWithPreload(() => import("containers/ShippingServiceProvider/Connect37Express")),
  // 发货记录
  ShippingRecord: lazyWithPreload(() => import("containers/ShippingRecord")),
  // 创建电子运单
  CreateShippingLabel: lazyWithPreload(() => import("containers/CreateShippingLabel")),
  ShippingLabelSelectShippingMethod: lazyWithPreload(() => import("containers/CreateShippingLabel/SelectShippingMethod")),
};

const rootScreens = [
  // 1. screenName
  // 2. { name: screenName, options?: {} }
  "SplashScreen",
  "DashboardLogin",
  "DashboardReports",
  "ShippingRecord",

  "FrameworkStates",
  "DevTools",
  "IntentionLauncher",
  "ComponentPlayground",
];

const rootStacks = [
  // {
  //   name: stackName,
  //   screens: 同 rootScreens
  // },
  {
    name: "SupplyChainStack",
    screens: [
      "SupplyChain",
      "SelectSupplier",
      "DashboardViewer",
    ],
  },
  {
    name: "ImportProductStack",
    screens: [
      "ImportProductFromMS",
      "ImportProductPreference",
    ],
  },
  {
    name: "SalesChannelStack",
    screens: [
      "SalesChannelList",
      "SalesChannelEfashion",
    ],
  },
  {
    name: "ShippingServiceProviderStack",
    screens: [
      "ShippingServiceProviderList",
      "ShippingServiceProviderSelectShippingMethods",
      "ShippingServiceProviderSetting37Express",
      "ShippingServiceProviderConnect37Express",
    ],
  },
  {
    name: "ShippingLabelStack",
    screens: [
      "CreateShippingLabel",
      "ShippingLabelSelectShippingMethod",
    ],
  },
];

const isInScreens = (name, screens) => {
  return screens.findIndex(i => i === name || i?.name === name) > -1;
};

export const findFirstRootStackByScreenIfNotRootScreen = screenName => {
  if (isInScreens(screenName, rootScreens)) {
    return;
  }
  return rootStacks.find(i => isInScreens(screenName, i.screens));
};

export const findBestMatchStackByScreen = screenName => {
  // 如果routes里存在配置了这个screen的stack，则优先用。(越迟进入的route，优先级越高)
  const rootState = navigationRef?.current?.getRootState();
  if (rootState?.routes) {
    for (let i = rootState.routes.length - 1; i >= 0; i--) {
      const route = rootState.routes[i];
      const rootStack = rootStacks.find(stack => stack.name === route.name);
      if (rootStack?.screens?.find?.(screen => screen === screenName || screen?.name === screenName)) {
        return rootStack;
      }
    }
  }
  return findFirstRootStackByScreenIfNotRootScreen(screenName);
};

const pickScreen = screenNameOrOption => {
  if (!screenNameOrOption) {
    return;
  }
  if (typeof screenNameOrOption === "string") {
    return ({
      name: screenNameOrOption,
      component: screenComponentMap[screenNameOrOption],
    });
  }
  if (typeof screenNameOrOption === "object") {
    return ({
      component: screenComponentMap[screenNameOrOption?.name],
      ...screenNameOrOption,
    });
  }
};

const pickScreens = list => list.map(pickScreen).filter(i => i?.component);

const LaunchScreen = createStack(pickScreens(["SplashScreen"]));

const EntriesStack = createStack([
  ...pickScreens(rootScreens),
  ...rootStacks.map(stack => ({
    ...stack,
    component: createStack(pickScreens(stack.screens)),
  })),
]);

const loadedScreenOrStackList = [];
export const preloadScreen = async screenName => {
  if (loadedScreenOrStackList.includes(screenName)) {
    return;
  }
  if (!screenComponentMap[screenName]) {
    return;
  }
  ScreenLoading.show(screenName);
  await screenComponentMap[screenName]?.preload?.();
  ScreenLoading.hide(screenName);
  loadedScreenOrStackList.push(screenName);
};
const preloadStack = async stackName => {
  if (loadedScreenOrStackList.includes(stackName)) {
    return;
  }
  const stack = rootStacks.find(i => i.name === stackName);
  if (!stack) {
    return;
  }
  ScreenLoading.show(stackName);
  await Promise.all(stack.screens.map(screen => preloadScreen(screen?.name || screen)));
  ScreenLoading.hide(stackName);
  loadedScreenOrStackList.push(stackName);
};
export const preloadScreenOrStack = name => Promise.all([preloadScreen(name), preloadStack(name)]);

class Router extends React.Component {

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this.backHandle);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.backHandle);
  }

  backHandle = () => {
    // // // 在根页面时按后退，提示再按一次就离开 APP，然后离开
    // if (this._tippedOneMoreBackWillLeave) {
    //   return false;
    // }
    // else {
    //   Toast(this.props.i18n.map.back_again_to_leave_app);
    //   this._tippedOneMoreBackWillLeave = true;
    //   clearTimeout(this._oneMoreBackWillLeaveTmo);
    //   this._oneMoreBackWillLeaveTmo = setTimeout(()=>{
    //     this._tippedOneMoreBackWillLeave = false;
    //   }, 2000);
    //   return true;
    // }
  }

  render() {
    const { isReady } = this.props;
    let timer = 0;
    return (
      <NavigationContainer
        ref={navigationRef}
        onReady={() => {
          _setNavigationReady();
        }}
        onStateChange={async () => {
          // const previousRouteName = routeNameRef.current;
          const currentRoute = navigationRef?.current?.getCurrentRoute() || {};
          const rootState = navigationRef?.current?.getRootState() || {};
          // 存储供异常监控使用
          _setLastRootState(rootState);
          _setLastCurrentRoute(currentRoute);
          if (currentRoute.name) {
            const params = {};
            let now = Date.now();
            if (now - timer > 50) {
              daService.logPV({ screenName: currentRoute.name, ...params });
            }
            timer = now;
          }
        }}
      >
        {!isReady ? (
          <LaunchScreen />
        ) : (
          <EntriesStack />
        )}
      </NavigationContainer>
    );
  }
}

export default connect((state) => ({
  isReady: state.app.isReady,
}))(Router);
